<template>
  <div>
    <div />
  </div>
</template>

<script>
export default {
  name: 'VbManajemenUser',
  components: {},
}
</script>
